import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppSettings } from "../util/AppSettings";

import { LazyLoadEvent } from "primeng/api";
import { tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { SolicitanteModel } from "../models/solicitante.model";
import { SolicitudModel } from "../models/solicitud";
import { CatasiaService } from "./catasia-service";

@Injectable({
  providedIn: "root",
})
export class RadicarService extends CatasiaService {
  loading = 0;

  constructor(private http: HttpClient) {
    super();
  }

  begin() {
    this.loading++;
  }

  end() {
    return tap(() => this.loading--, () => this.loading--);
  }

  get(url: string) {
    this.begin();
    return this.http.get(environment.API_URL + url, {
      headers: AppSettings.getHeader(),
    }).pipe(this.end());
  }

  getText(url: string) {
    return this.http.get(environment.API_URL + url, {
      headers: AppSettings.getHeader(),
      responseType: 'text'
    });
  }

  postJson(url: string, body: any) {
    this.begin();
    return this.post(url, body, {
      "Content-Type": "application/json"
    }).pipe(this.end());
  }

  postForm(url: string, body: any) {
    this.begin();
    return this.post(url, body, {
      "Content-Type": "application/x-www-form-urlencoded"
    }).pipe(this.end());
  }

  post(url: string, body: any, headers: any = {}) {
    this.begin();
    return this.http.post(environment.API_URL + url, body, {
      headers: AppSettings.getHeader(headers),
    }).pipe(this.end());
  }

  postText(url: string, body: any, headers: any = {}) {
    this.begin();
    return this.http.post(environment.API_URL + url, body, {
      headers: AppSettings.getHeader(headers),
      responseType: 'text'
    }).pipe(this.end());
  }

  put(url: string, body: any) {
    this.begin();
    return this.http.put(environment.API_URL + url, body, {
      headers: AppSettings.getHeader(),
    }).pipe(this.end());
  }
  delete(url: string) {
    this.begin();
    return this.http.delete(environment.API_URL + url, {
      headers: AppSettings.getHeader(),
    }).pipe(this.end());
  }

  newSolicitud(solicitud: SolicitudModel) {
    if (solicitud.contacto) {
      solicitud.contacto.id = null;
    }
    return this.post("solicitud", solicitud);
  }

  getSolicitud(id: number) {
    return this.get("solicitud/" + id);
  }

  putSolicitud(body: any) {
    return this.put(environment.host.ms_gateway.methods.solicitud, body);
  }

  radicarSolitud(id: number) {
    return this.post("solicitud/radicar/" + id, null);
  }

  buscarSolicitante(tipoDoc: number, numDoc: string) {
    return this.get(
      "solicitante/tipo-doc/" + tipoDoc + "/numero-doc/" + numDoc
    );
  }

  newSolicitante(data: SolicitanteModel) {
    return this.post("solicitante", data);
  }

  editSolicitante(data: SolicitanteModel) {
    return this.put("solicitante", data);
  }

  solicitanteTipos() {
    return this.get(environment.host.ms_gateway.methods.solicitanteTipos);
  }

  getTiposDocumento() {
    return this.get("documentoTipo");
  }

  getTiposSolicitud() {
    return this.http.get(environment.API_URL + "solicitudTipo", {
      headers: AppSettings.getHeader(),
    });
  }

  getFormasPeticion() {
    return this.get("formaPeticion");
  }

  getTiposTramite() {
    return this.get("tramiteTipo");
  }
  getTiposTramiteVentanilla() {
    return this.get(environment.host.ms_gateway.methods.tramitesVentanilla);
  }
  getTipoTramiteById(id: number): Observable<any> {
    return this.get(`tramiteTipo/${id}`);
  }

  getFormasTramite() {
    return this.http.get(environment.API_URL + "formaTramites", {
      headers: AppSettings.getHeader(),
    });
  }

  getSistemasEnvio() {
    return this.get("sistemaEnvio");
  }

  getSistemaEnvioById(id: number) {
    return this.get(`sistemaEnvio/${id}`);
  }

  getPredios() {
    return this.get("predio");
  }
  getPrediosByTramiteId(id: number, ligero?: boolean) {
    if (id) {
      let query: string = ligero ? "?ligero=true" : "";
      return this.get(`tramitePredio/findByTramite/${id}${query}`);
    }
    return { subscribe: () => { } };
  }
  putPredioByTramite(body: any) {
    return this.put("tramitePredio", body);
  }
  postPredioByTramite(body: any) {
    return this.post("tramitePredio", body);
  }
  deletePredioBytramite(id: number) {
    return this.delete(`tramitePredio/${id}`);
  }
  postBorrarPredios(ids: number[]) {
    return this.post(`tramitePredio/borrar?id=${ids}`, {});
  }

  getPrediosDummy(
    direccion: string,
    documentoPropietario: string,
    matriculaInmobiliaria: string,
    numeroPredial: string,
    numeroPredialAnterior: string
  ) {
    let body = {
      direccion: direccion,
      documentoPropietario: documentoPropietario,
      numeroPredial: numeroPredial,
      matriculaInmobiliaria: matriculaInmobiliaria,
      numeroPredialAnterior: numeroPredialAnterior,
      propietarios: true,
    };

    return this.post(
      environment.host.ms_gateway.methods.predioFindByFilters,
      body
    );
  }

  getPrediosByAnterior(numeroPredialAnterior: string) {
    return this.post(
      environment.host.ms_gateway.methods.predioByAnterior +
      `?buscar=${numeroPredialAnterior}`,
      {}
    );
  }

  getPrediosByDireccion(direccion: string) {
    return this.post(
      environment.host.ms_gateway.methods.predioByDireccion +
      `?buscar=${direccion}`,
      {}
    );
  }

  getPrediosByDocumento(documento: string) {
    return this.post(
      environment.host.ms_gateway.methods.predioByDocumento +
      `?buscar=${documento}`,
      {}
    );
  }

  getPrediosByfmi(fmi: string) {
    return this.post(
      environment.host.ms_gateway.methods.predioByfmi + `?buscar=${fmi}`,
      {}
    );
  }

  getPrediosByPredial(predial: string, nrodocumento: string = "", tipodocumento: string = "") {
    return this.post(
      environment.host.ms_gateway.methods.predioByPredial +
      `?buscar=${predial}&nrodocumento=${nrodocumento}&tipodocumento=${tipodocumento}`,
      {}
    );
  }

  getPredioByCodigo(codigo: string, refrescar?: boolean) {
    let query = refrescar ? `?refrescar=${refrescar}` : "";
    return this.post(
      environment.host.ms_gateway.methods.predioBuscar + codigo + query,
      {}
    );
  }

  getSolicitantesByIdTramite(id) {
    return this.get(
      environment.host.ms_gateway.methods.solicitanteBytramite + id
    );
  }

  getSolicitante(id: number) {
    return this.get(environment.host.ms_gateway.methods.solicitante + "/" + id);
  }

  getTipoPersonaService() {
    return this.get(environment.host.ms_gateway.methods.tipoPersona);
  }

  getDepartamentos() {
    return this.get(environment.host.ms_gateway.methods.departamento);
  }

  getCiudades() {
    return this.get(environment.host.ms_gateway.methods.ciudad);
  }

  getCiudadByDepartamento(id: number) {
    return this.get(
      environment.host.ms_gateway.methods.ciudadByDepartamento + id
    );
  }

  getDocumentoInformacionTipo(id: number): Observable<any> {
    return this.get(`tramiteDocumentoTipo/${id}`);
  }
  getDocumentosTramiteTipo(id: number) {
    return this.get(`tramiteDocumentoTipo/findByTramiteTipo/${id}`);
  }

  solicitudFindByNumero(id: string) {
    return this.get(
      environment.host.ms_gateway.methods.solicitudFindByNumero + id
    );
  }

  getSolicitudSinTramites() {
    return this.get(environment.host.ms_gateway.methods.solicitudSinTramites);
  }

  getTramites() {
    return this.get(environment.host.ms_gateway.methods.tramite);
  }
  getTramitesFilter(filter?: LazyLoadEvent) {
    return this.post(
      environment.host.ms_gateway.methods.tramiteFiltrar,
      filter
    );
  }
  getTramiteById(id: number) {
    return this.get(environment.host.ms_gateway.methods.tramite + `/${id}`);
  }
  getTramitesBySolicitudId(id: number) {
    return this.get(`tramite/findBySolicitud/${id}`);
  }
  getTramiteByPredio(predio: string) {
    return this.get(
      environment.host.ms_gateway.methods.tramiteFindByPredio + predio
    );
  }
  postTramite(body: any) {
    return this.post(environment.host.ms_gateway.methods.tramite, body);
  }
  putTramite(body: any) {
    return this.put(environment.host.ms_gateway.methods.tramite, body);
  }
  deleteTramite(id: number) {
    return this.delete(environment.host.ms_gateway.methods.tramite + "/" + id);
  }
  tramiteRadicar(id: number) {
    let body = {};
    return this.post(
      environment.host.ms_gateway.methods.tramiteRadicar + id,
      body
    );
  }
  archivoTipos() {
    return this.get(environment.host.ms_gateway.methods.archivoTipo);
  }

  postProgress(uri: string, documento: FormData) {
    return this.postReportProgress(
      uri,
      documento,
      this.http
    );
  }

  postDocumento(documento: FormData) {
    return this.post(
      environment.host.ms_gateway.methods.archivoGuardar,
      documento
    );
  }

  postDocumentoWithReportProgress(documento: FormData) {
    return this.postReportProgress(
      environment.host.ms_gateway.methods.archivoGuardar,
      documento,
      this.http
    );
  }

  postArchivoPublico(archivo) {
    return this.post(
      environment.host.ms_gateway.methods.archivoGuardarPublico,
      archivo
    );
  }
  deleteArchivoById(id: number) {
    return this.delete(`archivo/${id}`);
  }
  getTramiteDocumentoById(id: number): Observable<any> {
    return this.get(`tramiteDocumento/${id}`);
  }
  getTramiteDocumentosByTramiteId(id: number, origen?: string) {
    const query = origen ? `${id}?origen=${origen}` : id;
    return this.get(`tramiteDocumento/findByTramite/${query}`);
  }
  getTramiteDocumentosBySolicitanteId(id: number) {
    return this.get(`tramiteDocumento/findBySolicitanteId/${id}`);
  }
  // getTramiteDocumentos(id: number) {
  //   return this.get(`tramiteDocumento/findByTramite/${id}`);
  // }
  postTramiteDocumento(body: any): Observable<any> {
    return this.post("tramiteDocumento", body);
  }
  putTramiteDocumento(body: any) {
    return this.put("tramiteDocumento", body);
  }
  deleteTramiteDocumento(id: number) {
    return this.delete(`tramiteDocumento/${id}`);
  }
  deleteDocumentosTramiteId(id: number) {
    return this.delete(`tramiteDocumento/documentos/${id}`);
  }
  putTramiteTipo(body: any) {
    return this.put(environment.host.ms_gateway.methods.tramite, body);
  }
  getDocumento(id: number): Observable<Blob> {
    const httpHeaders = AppSettings.getHeader();
    const options = {
      headers: httpHeaders,
      responseType: "blob" as "json",
    };
    return this.http.get<any>(environment.API_URL + `archivo/${id}`, options);
  }

  getTramitesHistoricos() {
    return this.get(environment.host.ms_gateway.methods.tramiteHistorico);
  }

  getEstadosTramitesHistoricos() {
    return this.get(
      environment.host.ms_gateway.methods.tramiteHistoricoEstadosTramite
    );
  }

  getTiposSolicitudesHistoricos() {
    return this.get(
      environment.host.ms_gateway.methods.tramiteHistoricoTipoSolicitud
    );
  }

  getTiposTramitesHistoricos() {
    return this.get(
      environment.host.ms_gateway.methods.tramiteHistoricoTipoTramite
    );
  }

  postFechaInscripcionCatastral(tramitePredioId: number, fechaInscripcionCatastral: Date): Observable<any> {
    return this.post("derechoPropiedad/saveFechaInscripcionCatastral?tramitePredioId=" + tramitePredioId + "&fechaInscripcionCatastral=" + (fechaInscripcionCatastral ? fechaInscripcionCatastral.toISOString().substring(0, 10) : ""), null);
  }

  getRecursosInterpuestos(tramiteId: number) {
    return this.get(
      environment.host.ms_gateway.methods.recursosInterpuestos + tramiteId
    );
  }

  actualizarDetallesTramite(tramiteId: number, body: any) {
    return this.post(
      environment.host.ms_gateway.methods.actualizarDetalles + tramiteId,
      body
    );
  }
}
