import { ModuloPermisosModel } from "./modulo.model";

export class UserInfoModel {
  codigoRol: string;
  modoRol: string;
  departamento: string;
  municipio: string;
  nombre: string;
  oficina: string;
  rol: string;
  urlFoto: string;
  version: string;
  build: string;
  firma: boolean;
  pruebas: boolean;
  titulo: string;
  admin: boolean;
  tramiteDias: number;
  tramiteDiasEntregaDocs: number;
  //urlVisorPredio: string;
  //urlVisorTramite: string;
  urlVisorPredio2: string;
  urlVisorTramite2: string;
  licencia: any;
  revisionTecnicaAdministrativos: boolean;
  certNotAfter: any;
  diasCertNotAfter: number;
  soporte: boolean;
  saas: boolean;
  menu: ModuloPermisosModel;
  geo: boolean;
  firmaConstancias: boolean;
  certificadosSinDocumentoMejora: boolean;
  plantillaResolucion: boolean;
  despachoConsecutivoExterno: boolean;
  token: string;
  codigoMunicipio: string;
  prediosArchivosExternos: boolean;
}
